import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {ConsultationsDiagnosticsBenefit} from '@peachy/legacy-domain'
import {useCurrentQuote} from '../../QuoteController'

const benefitTemplate = ConsultationsDiagnosticsBenefit()

export default function ConsultationsCard() {

    return (
        <BenefitCard header={'Consultations & Diagnostics'} className={styles.consultations} benefitTemplate={benefitTemplate}>
            <p>Don't dwell on worrying symptoms & get a diagnosis – fast.</p>
            <p>We provide cover (up to your chosen annual limit) for:</p>
            <ul>
                <li>Access to outpatient consultations with specialists & diagnostic tests & procedures (e.g. blood
                    tests, scans, endoscopies etc)
                </li>
                <li>Cancer diagnosis</li>
            </ul>
        </BenefitCard>
    )
}

