import React, {ReactNode, useContext, useState} from 'react'
import {API} from '@aws-amplify/api'

import {createPolicyApi, GraphApi} from '../service/individual-services/PolicyService/PolicyApi'
import {createPolicyService} from '../service/individual-services/PolicyService/PolicyService'

const PolicyContext = React.createContext(null)


type PolicyControllerProps = {
    children: ReactNode
}

export default function PolicyController({children}: PolicyControllerProps) {

    const [policyService, _] = useState(() => {
        return createPolicyService(createPolicyApi(API as GraphApi))
    })

    return (
        <PolicyContext.Provider value={policyService}>
            {children}
        </PolicyContext.Provider>
    )
}
export function usePolicyService() {
    return useContext(PolicyContext)
}



