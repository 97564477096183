import {
    daysInMonth,
    firstDayOfMonth,
    isWithinBounds,
    memoize,
    monthYearAfter,
    monthYearBefore,
    today,
    MonthYearArray
} from '@peachy/utility-kit-pure'

export const rawTableDataFromCache = memoize(rawTableData)

export function rawTableData(thisMonthYear: MonthYearArray) {

    const lastMonthYear = monthYearBefore(thisMonthYear)
    const nextMonthYear = monthYearAfter(thisMonthYear)

    const daysLastMonth = daysInMonth(lastMonthYear)
    const daysThisMonth = daysInMonth(thisMonthYear)

    const firstDayOfTheMonth = firstDayOfMonth(thisMonthYear)

    const daysNeededFromLastMonth = (firstDayOfTheMonth - 1) || 7
    const daysNeededFromNextMonth = 42 - (daysNeededFromLastMonth + daysThisMonth)

    const firstDayInTable = (daysLastMonth + 1) - daysNeededFromLastMonth

    let currentRow = []
    const table = []

    for (let d = firstDayInTable; d <= daysLastMonth; d++) {
        currentRow = updateTable(d, lastMonthYear, currentRow, table)
    }
    for (let d = 1; d <= daysThisMonth; d++) {
        currentRow = updateTable(d, thisMonthYear, currentRow, table)
    }
    for (let d = 1; d <= daysNeededFromNextMonth; d++) {
        currentRow = updateTable(d, nextMonthYear, currentRow, table)
    }
    return table
}

function updateTable(day, monthYear, currentRow, table) {
    currentRow.push([day, ...monthYear])
    if (currentRow.length % 7 === 0) {
        table.push(currentRow)
        return []
    }
    return currentRow
}


export function resolveDisplayDate (requestedDisplayDate: Date, selectedDate: Date, minDate: Date, maxDate: Date): Date {

    const now = today()
    if (isWithinBounds(requestedDisplayDate, minDate, maxDate)) {
        return requestedDisplayDate
    }

    if (isWithinBounds(selectedDate, minDate, maxDate)) {
        return selectedDate
    }

    if (isWithinBounds(now, minDate, maxDate)) {
        return now
    }

    return minDate ?? maxDate
}
