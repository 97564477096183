import React from 'react'
import styles from './Speciality.module.css'
import {NOOP, classList} from '@peachy/utility-kit-pure'

export type SpecialityProps = {
    name:string
    className?: string
    onClick?(name: string)
    small?: boolean
}

export default function Speciality({name, className, onClick = NOOP, small = false}: SpecialityProps) {

    // const rootClasses = classList(styles.Speciality, className)
    const rootClasses = classList(styles.Speciality, small && styles.small, className)

    return (
        <li className={rootClasses} onClick={() => {onClick(name)}} >
            {name}
        </li>
    )
}
