import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {useState as useHookState} from '@hookstate/core'
import {API} from '@aws-amplify/api'

import {BenefitTypes, LifeTypes, Quote, UkRegions} from '@peachy/legacy-domain'
import {createQuoteApi, createQuoteService, QuoteService, QuoteSession} from '@peachy/quote-client'

const QuoteContext = React.createContext<QuoteService | null>(null)


type QuoteControllerProps = {
    children: ReactNode
}


export default function QuoteController({children}: QuoteControllerProps) {

    const [quoteService, _] = useState<QuoteService>(() => {
        // @ts-ignore
        const quoteService = createQuoteService(createQuoteApi(API as GraphApi))
        const quoteSession = loadQuoteSession()

        if (quoteSession) {
            quoteService.loadQuote(quoteSession.quote, quoteSession.quoteStage)
            quoteService.requestQuote()

        } else {
            quoteService.createLife(LifeTypes.PRIMARY)
        }

        return quoteService
    })


    useEffect(() => {
        const onHide = () => {
            saveQuoteSession({
                quote: quoteService.getCurrentQuote().value,
                quoteStage: quoteService.getCurrentQuoteStage().value
            })
        }

        window.addEventListener('pagehide', onHide)

        return () => {
            window.removeEventListener('pagehide', onHide)
        }

    }, [])


    return (
        <QuoteContext.Provider value={quoteService}>
            {children}
        </QuoteContext.Provider>
    )
}

export function useDraftLife() {
    const quoteService = useContext(QuoteContext)
    return useHookState(quoteService.getCurrentQuoteStage().data.draftLife)
}

export function useDraftPlan() {
    const quoteService = useContext(QuoteContext)
    return useHookState(quoteService.getCurrentQuoteStage().data.draftPlan)
}

export function useCurrentQuote() {
    const quoteService = useContext(QuoteContext)
    return useHookState(quoteService.getCurrentQuote())
}

export function useCurrentStartDate() {
    const quoteService = useContext(QuoteContext)
    return useHookState(quoteService.getCurrentQuote().request.startDate)
}


export function useQuoteStage() {
    const quoteService = useContext(QuoteContext)
    return useHookState(quoteService.getCurrentQuoteStage())
}

export function useQuoteService() {
    return useContext(QuoteContext)
}



const QUOTE_SESSION_KEY = 'QUOTE_SESSION'


function saveQuoteSession(quoteSession: QuoteSession) {
    if (quoteSession.quote && quoteSession.quoteStage) {
        sessionStorage.setItem(QUOTE_SESSION_KEY, JSON.stringify(quoteSession))
    }
}


function loadQuoteSession(): QuoteSession {
    const session = sessionStorage.getItem(QUOTE_SESSION_KEY)

    if (session) {
        Promise.resolve().then(() => {
            sessionStorage.removeItem(QUOTE_SESSION_KEY)
        })

        return JSON.parse(session)
    } else {
        return null
    }

}
