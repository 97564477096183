import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {fetchServiceConfig} from '@peachy/core-domain-client/src/fetchServiceConfig'
import {useServicePatch} from '../service/WebShopServiceController'
import {dump} from '@peachy/utility-kit-pure'

let ROOT_DOMAIN: string

const apis = [
    'PaymentsApi',
    'LookupApi',
    'MarketplaceApi',
    'SubscriptionApi',
    'QuoteApi',
    'Intercom'
]



export async function configureAws() {

    const servicePatchUrl = useServicePatch()

    const fetchServicePatch = (service: string) => fetchServiceConfig(service, servicePatchUrl)

    const domainConfig = await fetchServicePatch('DomainConfig')
    ROOT_DOMAIN = domainConfig.rootDomainName

    const iamConfig = await fetchServicePatch('IamConfig')

    Auth.configure(iamConfig)

    const apiConfigs = {
        endpoints: [] as string[]
    }
    for (const api of apis) {
        const apiConfig = await fetchServicePatch(api)
        apiConfigs.endpoints.push({
            name: api,
            ...apiConfig
        })
    }

    dump(apiConfigs)

    API.configure(apiConfigs)
}
