
import React, {ReactNode, useContext} from 'react'

export type ScopeProps<T> = {
    value: T,
    children: ReactNode
}

const ScopeContext = React.createContext<any>(null)

export default function Scope<T>({value, children}: ScopeProps<T>) {
    return (
        <ScopeContext.Provider value={value}>
            {children}
        </ScopeContext.Provider>
    )
}


export function useScope<T>(): T {
    return useContext(ScopeContext)
}
