import React, {useEffect, useState} from 'react'
import QuoteController, {useCurrentQuote, useQuoteStage} from './QuoteController'
import useEnsureGoogleMaps from '../App/useEnsureGoogleMaps'
import styles from './QuoteJourney.module.css'
import Scope from '../elements/Scope'
import StageRouter, {RouteStageProps} from './StageRouter/StageRouter'
import {classList} from '@peachy/utility-kit-pure'
import useScrollPosition from '../hooks/useScrollPosition'
import QuoteFooter from './QuoteFooter/QuoteFooter'
import useDirectionOfTravel from '../hooks/useDirectionOfTravel'
import Spinner from '../elements/Spinner/Spinner'
import CaptureLife from './CaptureLife/CaptureLife'
import AndBuy from './AndBuy/AndBuy'
import TheEnd from './AndBuy/TheEnd/TheEnd'
import BuildQuote from './BuildQuote/BuildQuote'
import {QuoteStageName, QuoteStageNames} from '@peachy/quote-client'
import useDataThrottle from '../hooks/useDataThrottle'
import {useDetectPromo} from '../hooks/usePromos'

export default function QuoteJourney() {

    const googleApiLoaded = useEnsureGoogleMaps() === 'loaded'

    return googleApiLoaded && (
        <QuoteController>
            <QuotePage/>
        </QuoteController>
    )
}


const BuildQuoteStages: QuoteStageName[] = [
    QuoteStageNames.BUILD_QUOTE,
    QuoteStageNames.EDIT_PLAN,
    QuoteStageNames.ERROR,
    QuoteStageNames.PENDING,
]


function QuotePage() {

    useDetectPromo()

    const quoteStage = useQuoteStage()
    const [quotePortal, setQuotePortal] = useState<HTMLElement>()

    const isBuildQuote = BuildQuoteStages.includes(quoteStage.stage.value)

    const [showHeaderQuote, setShowHeaderQuote] = useState(true)


    const throttledStage = useDataThrottle(quoteStage.stage.value, 600)


    const scrollPosition = useScrollPosition()
    const scrollTrajectory = useDirectionOfTravel(scrollPosition)

    const renderHeaderShadow = scrollPosition > 0 && isBuildQuote

    const isTheEnd = quoteStage.stage.value == QuoteStageNames.BOUGHT

    useEffect(() => {

        if (scrollTrajectory === 'DOWN') {
            setShowHeaderQuote(false)
        } else {
            if (scrollPosition === 0 && scrollTrajectory === 'UP') {
                setShowHeaderQuote(true)
            }
        }

    }, [scrollPosition, isBuildQuote])


    return <>
        <header
            className={classList(styles.QuoteHeader, renderHeaderShadow && styles.HeaderShadow, isTheEnd && styles.theEnd)}>
            <nav ref={setQuotePortal} className={styles.QuoteNav}/>
            {/*{isBuildQuote &&*/}
            {/*<HeaderQuote show={showHeaderQuote}/>*/}
            {/*}*/}
        </header>
        <Scope value={quotePortal}>
            <StageRouter currentStage={quoteStage.stage.value}>
                <CaptureLife onStage={QuoteStageNames.CREATE_LIFE}/>
                <BuildQuote onStages={BuildQuoteStages}/>
                <AndBuy onStage={QuoteStageNames.APPLY}/>
                <TheEnd onStage={QuoteStageNames.BOUGHT}/>
            </StageRouter>
        </Scope>
        {throttledStage === QuoteStageNames.PENDING &&
            <Spinner/>
        }

        {isBuildQuote &&
        <QuoteFooter show={!showHeaderQuote}/>
        }

    </>
}

