import React, {useEffect, useState} from 'react'
import styles from './CapturePassword.module.css'
import {State as HookState} from '@hookstate/core'
import {Draft, dateFromIso, Fields, classList} from '@peachy/utility-kit-pure'
import {Life, toClass, validate} from '@peachy/legacy-domain'
import useToggles from '../../../hooks/useToggles'
import CaptureForm, {CaptureFormProps} from '../../DraftLife/CaptureForm'
import TextBox from '../../Elements/TextBox/TextBox'
import Expander from '../../../elements/Expander/Expander'
import {useCurrentQuote} from '../../QuoteController'
import useIntercom from '../../../hooks/useIntercom'


export type CapturePasswordProps = {
    onSubmit(e?: unknown): unknown
    submitError?: string
    draftLife: HookState<Draft<Life>>
}

export default function CapturePassword({onSubmit, draftLife, submitError}: CapturePasswordProps) {

    const quote = useCurrentQuote()
    const intercom = useIntercom()
    const startDate = dateFromIso(quote.request.startDate.value)

    const visitedFields = useToggles<Fields<Life>>(['password', 'email'])
    const validationErrors = validate(toClass(draftLife.value, Life), startDate, Life, 'BUY')

    const [showPassword, setShowPassword] = useState(false)

    const showIconClasses = classList(showPassword ? 'fal fa-eye-slash' : 'fal fa-eye', styles.showPassword)

    const capturePasswordProps: CaptureFormProps<Life> = {
        buttonId: 'create-account',
        onSubmit,
        instance: draftLife,
        submitError,
        visitedFields,
        validationErrors,
        headers: <>
            <h4>Great stuff!</h4>
            <h4>Now let's secure your account</h4>
        </>,
        captureFields: ['password', 'email']
    }

    useEffect(() => intercom.trackEvent('capture-password'), [])

    return (<>
        <CaptureForm  {...capturePasswordProps}>
            <Expander expanded={!!submitError} tag={'fieldset'}>
                <TextBox autoFocus placeholder={'Email address'} value={draftLife.email.value}
                         setValue={draftLife.email.set} onBlur={() => visitedFields.toggleOn('email')}/>
            </Expander>
            <fieldset className={styles.CapturePassword}>
                <TextBox redact={!showPassword} autoFocus placeholder={'Enter your password'}
                         value={draftLife.password.value} setValue={draftLife.password.set}
                         onBlur={() => visitedFields.toggleOn('password')}/>
                <button type={'button'} onClick={(e) => {
                    setShowPassword(show => !show);
                    (e.target as HTMLElement).parentElement.blur()
                }}>
                    <i className={showIconClasses}>Show password</i>
                </button>
            </fieldset>
        </CaptureForm>
    </>)
}



