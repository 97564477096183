import {LifeQuoteResponse, QuoteRequest, QuoteResponse} from '../quote'
import {
    BenefitValues,
    IndividualQuoteRequest,
    IndividualQuoteResponse, LegacyQuoteRegion,
    LegacyQuoteRegions,
    LegacyQuoteRequest,
    LegacyQuoteResponse,
} from './legacy-quote'
import {Life} from '../people'
import {BenefitTypes, Plan} from '../plans'
import {UkRegions} from '../location/uk-regions'
import {KeyMapped} from '@peachy/core-domain-pure'

export function mapToLegacyQuoteRequest(
    quoteRequest: QuoteRequest
): LegacyQuoteRequest {
    const startDate = quoteRequest.startDate
    const defaultPlan = quoteRequest.getDefaultPlan()

    return {
        quoteRequestId: quoteRequest.id,
        primaryIndividual: mapToLegacyLife(
            quoteRequest.getPrimaryLife(),
            defaultPlan,
            startDate
        ),
        secondaryIndividual: mapToLegacyLife(
            quoteRequest.getSecondaryLife(),
            defaultPlan,
            startDate
        ),
        children: quoteRequest
            .getDependents()
            ?.map((d) => mapToLegacyLife(d, defaultPlan, startDate)),
        includeSecondaryIndividual: true,
    }
}

function mapToLegacyLife(
    life: Life,
    plan: Plan,
    startDate: string
): IndividualQuoteRequest {
    if (!life) return undefined

    const requiredBenefits = plan.benefits?.map((b) => b.name)
    const benefitLimits: BenefitValues = {}


    plan.benefits
        .filter((b) => !!b.limit)
        .forEach((b) => {
            benefitLimits[b.name] = b.limit * 100
        })

    // force therapies limit
    benefitLimits[BenefitTypes.THERAPIES] = 250000

    return {
        lifeId: life.id,
        includeBreakdown: true,
        gender: life.gender,
        region: legacyRegionMap[life.address?.region],
        address: {
            addressComponents: life.address?.addressComponents?.map(
                (c) => c.longName
            ),
            display: life.address?.display,
            location: life.address?.location,
            postcode: life.address?.postcode,
        },

        birthdate: life.birthDate,
        coverStartDate: startDate,

        requiredBenefits,
        benefitLimits,
    }
}

export function mapFromLegacyQuoteResponse(
    legacyResponse: LegacyQuoteResponse,
    lifePlans: KeyMapped<string>

): QuoteResponse {
    const lives: LifeQuoteResponse[] = []



    const lifeOne: LifeQuoteResponse = mapFromLegacyIndividualQuoteResponse(
        legacyResponse.primaryIndividual,
        lifePlans[legacyResponse.primaryIndividual.lifeId]
    )
    const lifeTwo: LifeQuoteResponse = mapFromLegacyIndividualQuoteResponse(
        legacyResponse.secondaryIndividual,
        lifePlans[legacyResponse.primaryIndividual.lifeId]
    )
    const children: LifeQuoteResponse[] = legacyResponse.children?.map((c) =>
        mapFromLegacyIndividualQuoteResponse(c, lifePlans[c.lifeId])
    )

    lives.push(lifeOne)
    if (lifeTwo) lives.push(lifeTwo)
    if (children?.length) lives.push(...children)

    return {
        lives,
        quoteRequestId: legacyResponse.quoteRequestId,
        quoteResponseId: legacyResponse.quoteResponseId,
        timestamp: new Date(legacyResponse.timestamp).toISOString(),
        totalPolicyPrice: legacyResponse.totalPolicyPrice,
    }
}

function mapFromLegacyIndividualQuoteResponse(
    legacyIndividualResponse: IndividualQuoteResponse,
    planId: string
): LifeQuoteResponse {
    if (!legacyIndividualResponse) return undefined

    return {
        lifeId: legacyIndividualResponse.lifeId,
        planId,
        benefitPrices: Object.entries(legacyIndividualResponse.pricesByBenefit).map(
            ([k, v]) => {
                return {
                    benefitName: k,
                    benefitPrice: v,
                }
            }
        ),
        benefitPriceBreakdown: {
            baseRiskCost: legacyIndividualResponse.benefitPriceBreakdown.a_baseRiskCost,
            adjustedForCorrelation: legacyIndividualResponse.benefitPriceBreakdown.b_adjustedForCorrelation,
            adjustedForContingency: legacyIndividualResponse.benefitPriceBreakdown.c_adjustedForContingency,
            adjustedForLossRatio: legacyIndividualResponse.benefitPriceBreakdown.d_adjustedForLossRatio,
            adjustedForIpt: legacyIndividualResponse.benefitPriceBreakdown.e_adjustedForIpt,
            adjustedForPriceResolution: legacyIndividualResponse.benefitPriceBreakdown.g_adjustedForPriceResolution,
        },
        totalPlanPrice: legacyIndividualResponse.totalPlanPrice,
    }
}


export const legacyRegionMap: {[k: string]: LegacyQuoteRegion} = {
    // [UkRegions['Crown Dependencies']]: null,
    [UkRegions['Scotland']]: LegacyQuoteRegions.SCOTLAND,
    // [UkRegions['Northern Ireland']]: null,
    [UkRegions['Wales']]: LegacyQuoteRegions.WALES,
    [UkRegions['North East England']]: LegacyQuoteRegions.NORTHEAST,
    [UkRegions['North West England']]: LegacyQuoteRegions.NORTHWEST,
    [UkRegions['Yorkshire and the Humber']]: LegacyQuoteRegions.MIDLANDS,
    [UkRegions['West Midlands']]: LegacyQuoteRegions.MIDLANDS,
    [UkRegions['East Midlands']]: LegacyQuoteRegions.MIDLANDS,
    [UkRegions['East of England']]: LegacyQuoteRegions.EAST,
    [UkRegions['London']]: LegacyQuoteRegions.LONDON,
    [UkRegions['South East England']]: LegacyQuoteRegions.SOUTHEAST,
    [UkRegions['South West England']]: LegacyQuoteRegions.SOUTHWEST,
} as const
