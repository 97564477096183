import React, {useEffect} from 'react'
import TextBox from '../../Elements/TextBox/TextBox'
import {CHILD_MAX_AGE, DEPENDENT_MAX_AGE, Life, LIFE_MAX_AGE, LifeTypes, toClass} from '@peachy/legacy-domain'
import CaptureForm, {CaptureFieldsProps, CaptureFormProps} from '../../DraftLife/CaptureForm'
import DateInput from '../../Elements/DateInput/DateInput'
import {midPoint, plusYears, today, tomorrow} from '@peachy/utility-kit-pure'
import GenderInput from '../../Elements/Gender/GenderInput'
import Expander from '../../../elements/Expander/Expander'
import {useCheckedUsernames, useIamService, useIamStage} from '../../IamController'
import isEmail from 'validator/es/lib/isEmail'
import Toggle from '../../../elements/Toggle/Toggle'
import styles from './CaptureOtherLife.module.css'

export default function SecondaryLife(props: CaptureFieldsProps<Life>) {
    const {instance, visitedFields} = props

    const lifeClass = toClass(instance.value, Life)
    const isPartner = lifeClass.is(LifeTypes.SECONDARY)

    const emailRequired = isPartner || lifeClass.age() >= CHILD_MAX_AGE

    const emailAvailability = useCheckedUsernames().value

    const emailUnavailable = emailAvailability[instance.email.value] === false
    const emailAvailable = emailAvailability[instance.email.value] === true

    const iamService = useIamService()
    const iamStage = useIamStage()

    const setEmail = (email: string) => {
        instance.email.set(email)
        if (email && isEmail(email)) {
            iamService.checkUsername(email)
        }
    }

    const captureNameProps: CaptureFormProps<Life> = {
        ...props,
        buttonId: 'capture-life',
        vetoSubmit: emailRequired && !emailAvailable,
        submitError: emailUnavailable ? 'Sorry, that email address is not available' : null,
        headers: isPartner
            ? <h3>Add your partner</h3>
            : <h3>Add your child</h3>
        ,
        buttonText: isPartner
            ? 'Add partner'
            : 'Add child',
        captureFields: ['firstname', 'lastname', 'gender', 'birthDate', 'email', 'ukResidentConfirmed']
    }

    const minDate = isPartner
        ? plusYears(-(LIFE_MAX_AGE + 1), tomorrow())
        : plusYears(-(DEPENDENT_MAX_AGE + 1), tomorrow())

    const maxDate = isPartner
        ? plusYears(-CHILD_MAX_AGE, today())
        : today()

    const middleAge = midPoint(minDate, maxDate)

    const lifeReference = isPartner ? 'partner/spouse' : 'child'

    useEffect(() => {
        const email = instance.email.value

        if (email && isEmail(email)) {
            iamService.checkUsername(email)
        }

    }, [iamStage.stage.value])


    return (
        <CaptureForm  {...captureNameProps}>
            <fieldset>
                <legend>What's their name?</legend>
                <TextBox autoCapitalize autoFocus placeholder={'First name'} value={instance.firstname.value}
                         setValue={instance.firstname.set} onBlur={() => visitedFields.toggleOn('firstname')}/>
                <TextBox autoCapitalize placeholder={'Last name'} value={instance.lastname.value}
                         setValue={instance.lastname.set} onBlur={() => visitedFields.toggleOn('lastname')}/>
            </fieldset>
            <DateInput minDate={minDate} maxDate={maxDate} header={'Enter their birthdate'} legend={`What's their date of birth?`}
                       defaultPickerDate={middleAge} onAllVisited={() => visitedFields.toggleOn('birthDate')}
                       dateState={instance.birthDate}/>

            <GenderInput genderState={instance.gender} legend={`What's their gender?`}/>

            <Expander tag={'fieldset'} expanded={emailRequired}>
                <div className={styles.email} >
                    <legend>And what's their email?</legend>
                    <TextBox placeholder={'Email address'} value={instance.email.value} setValue={setEmail}
                             onBlur={() => visitedFields.toggleOn('email')}/>
                </div>
            </Expander>
            <fieldset>
                <label className={styles.optIns}>
                    <span>I confirm the following</span>
                    <Toggle className={styles.toggle} state={instance.ukResidentConfirmed.value} setState={instance.ukResidentConfirmed.set}/>
                    <ul>
                        <li>My {lifeReference} lives at the same address as me</li>
                        <li>They live at this address for at least 180 days per year</li>
                        <li>They have been registered with a UK NHS GP for at least 6 months, or can provide full medical records in English</li>
                    </ul>
                </label>
            </fieldset>

        </CaptureForm>
    )
}
