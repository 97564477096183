import {API} from '@aws-amplify/api'
import {useRef} from 'react'

export default function useIntercom() {

    const intercomInterface = useRef({
        async convertVisitorToLead(leadDetails: {email?: string, name?: string, phone?: string}) {
            try {
                const visitorId = this.getVisitorId(true)
                if (visitorId) {
                    log('convert visitor id: ', visitorId, 'to lead')
                    const response = await API.put(IntercomApi, '/visitorToLead/' + visitorId, {body: leadDetails})

                    log('visitorToLead response', {response})

                    return response
                } else {
                    log('no visitor to convert to lead')
                }

            } catch (e) {
                console.log('ERROR received from intercom', e)

                // don't let tracking errors break the FE
            }
        },
        getVisitorId(rebootIfUndefined = false) {
            let id = window.Intercom('getVisitorId')
            if (!id && rebootIfUndefined) {
                this.reboot()
                id = window.Intercom('getVisitorId')
            }
            return id
        },
        trackEvent(eventName: string, eventMetadata?: object) {
            try {
                log('track event:', eventName, JSON.stringify(eventMetadata, null, 2))
                window.Intercom('trackEvent', eventName, eventMetadata)
            } catch (e) {
                console.log('ERROR received from intercom', e)
                // don't let tracking errors break the FE
            }
        },
        reboot() {
            this.shutdown()
            this.boot()
        },
        shutdown() {
            log('shutdown session')
            window.Intercom('shutdown')
        },
        boot() {
            log('boot')
            window.Intercom('boot', window.intercomSettings)
        }
    })
    return intercomInterface.current
}

function log(message?: any, ...optionalParams: any[]) {
    console.log('[Intercom]' + message, ...optionalParams)
}

const IntercomApi = 'Intercom'