import React, {useEffect, useState} from 'react'
import styles from './ListSelector.module.css'
import {NOOP, classList, trueMod, clampNumber} from '@peachy/utility-kit-pure'
import useDismissible from '../../hooks/useDismissible'

export type ListSelectorProps = {
    list: string[]
    selection?: string | number
    selectionIsLocus?:boolean
    onSelect?(selection: string, index?: number): void
    onDismiss?(): void
    enabled?: boolean
    className?: string
    externalKeyboardEvent?: React.KeyboardEvent
}


export default function ListSelector({list, selection, selectionIsLocus = false, onSelect = NOOP, onDismiss = NOOP, externalKeyboardEvent, className, enabled = true}: ListSelectorProps) {

    let initialLocus: number
    if (selectionIsLocus) {
        initialLocus = selection as number
    } else {
        initialLocus = clampNumber(list.indexOf(selection as string), 0, list.length)
    }

    const [locus, setLocus] = useState(initialLocus)

    const classes = classList(styles.ListSelector, className)

    const listRef = useDismissible<HTMLUListElement>(onDismiss)


    const onKeyDown = (e: React.KeyboardEvent) => {
        if (!enabled) return

        if (e.key == 'ArrowUp') {
            e.preventDefault()
            const targetLocus = trueMod(locus - 1, list.length)
            setLocus(targetLocus)
            ensureIsInView(targetLocus, listRef.current)

        } else if (e.key == 'ArrowDown') {
            e.preventDefault()
            const targetLocus = trueMod(locus + 1, list.length)
            setLocus(targetLocus)
            ensureIsInView(targetLocus, listRef.current)

        } else if (['Enter', 'Tab'].includes(e.key) ) {
            onSelect(list[locus], locus)

        } else if (e.key == 'Escape') {
            setLocus(initialLocus)
            ensureIsInView(initialLocus, listRef.current)
            onDismiss()
        }
    }

    const onClickOption = (targetLocus: number) => {
        setLocus(targetLocus)
        const targetItem = getListItem(targetLocus, listRef.current)
        if (!isInView(targetItem, listRef.current)) {
            targetItem.scrollIntoView({behavior: 'smooth', block: 'nearest'})
        }
        onSelect(list[targetLocus], targetLocus)
    }

    const items = list.map((item, index) => {
        const Tag = index == locus ? 'mark' : 'span'
        return (
            <li onClick={() => onClickOption(index)} key={index}>
                <Tag>{item}</Tag>
            </li>
        )
    })

    // useEffect(() => {
    //     const locusItem = getListItem(locus, listRef.current)
    //
    //     if (!isInView(locusItem, listRef.current)) {
    //         locusItem.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    //     }
    // }, [])

    useEffect(() => {
        setLocus(initialLocus)
        const locusItem = getListItem(locus, listRef.current)
        if (locusItem && !isInView(locusItem, listRef.current)) {
            locusItem.scrollIntoView({behavior: 'smooth', block: 'nearest'})
        }

    }, [selection])


    useEffect(() => {
        if (externalKeyboardEvent) {
            onKeyDown(externalKeyboardEvent)
        }
    }, [externalKeyboardEvent])

    return (
        <ul ref={listRef} tabIndex={enabled ? 0: undefined} onKeyDown={onKeyDown} className={classes}>
            {items}
        </ul>
    )
}


function ensureIsInView(targetLocus: number, listElement: HTMLUListElement) {
    const targetItem = getListItem(targetLocus, listElement)
    if (!isInView(targetItem, listElement)) {
        targetItem.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }
}

function getListItem(index: number, list: HTMLUListElement): HTMLLIElement {
    return list.children.item(index) as HTMLLIElement
}

function isInView(item: HTMLLIElement, list: HTMLUListElement) {
    const itemBounds = item.getBoundingClientRect()
    const listBounds = list.getBoundingClientRect()
    return listBounds.top <= itemBounds.top && listBounds.bottom >= itemBounds.bottom
}

