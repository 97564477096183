import React, {useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import {useState as useHookState} from '@hookstate/core'
import {useScope} from '../../elements/Scope'
import PeachyLogo from '../../Logo/PeachyLogo'
import {dateFromIso, Draft, NOOP} from '@peachy/utility-kit-pure'

import styles from '../QuoteJourney.module.css'
import {useCurrentQuote, useQuoteService} from '../QuoteController'
import {RouteStageProps} from '../StageRouter/StageRouter'
import CapturePassword from './CapturePassword/CapturePassword'
import CapturePhone from './CapturePhone/CapturePhone'
import {CardDetails, Life, Quote, toBlueprint, toClass} from '@peachy/legacy-domain'
import {useIamService} from '../IamController'

import * as libPhoneNumber from 'libphonenumber-js'
import {usePolicyService} from '../PolicyController'
import Spinner from '../../elements/Spinner/Spinner'
import Switcher from '../../elements/Switcher/Switcher'
import {QuoteStageName} from '@peachy/quote-client'
import PaymentsController from '../PaymentsController'
import useAsyncEffect from 'use-async-effect'
import CaptureCard from './CaptureCard/CaptureCard'
import useDataThrottle from '../../hooks/useDataThrottle'
import useIntercom from '../../hooks/useIntercom'
import {useGetPromoIfValidForPrimaryLife} from '../../hooks/usePromos'
import {isBefore, startOfToday} from 'date-fns'

const keyframes = [
    {
        transformOrigin: '50% 50%',
        zIndex: 10
    },
    {
        transform: 'scale(20, 100)',
        zIndex: 10
    }
]

const options: KeyframeAnimationOptions = {
    duration: 1000,
    fill: 'forwards',
    easing: 'ease',
}

export type AndBuyProps = RouteStageProps<QuoteStageName>


export default function AndBuy({}: AndBuyProps) {

    const quoteService = useQuoteService()
    const quote = useCurrentQuote()
    const intercom = useIntercom()
    const quoteClass = toClass(quote.value, Quote)

    const iamService = useIamService()

    const policyService = usePolicyService()

    const [hasBought, setHasBought] = useState(false)
    const [isBuying, setIsBuying] = useState(false)


    const showSpinner = useDataThrottle(isBuying, 800)


    const [submitError, setSubmitError] = useState('')

    const currentPageStage = useHookState(0)

    const draftFirstLife = useHookState<Draft<Life>>({})

    const applyButtonRef = useRef<HTMLButtonElement>()

    const promo = useGetPromoIfValidForPrimaryLife()

    const draftCard = useHookState<Draft<CardDetails>>({
        name: null,
        promoCode: promo?.code
    })

    const isQuoteStartDateBeforeToday = () => isBefore(dateFromIso(quote.request.startDate.value), startOfToday())
    const reOpenQuote = () => {
        quoteService.reopen()
        currentPageStage.set(0)
    }

    useEffect(() => {
        if(isQuoteStartDateBeforeToday()) {
            reOpenQuote()
        }
    }, [currentPageStage.value])

    useEffect(() => {
        if (submitError && submitError.length > 0) {
            intercom.trackEvent('purchase-error', {message: submitError})
        }
    }, [submitError])

    const submitStage = () => currentPageStage.set(stage => stage + 1)

    const onBack = () => {
        currentPageStage.set(stage => {
            if (stage === 0) {
                quoteService.reopen()
                return 0
            } else {
                return stage - 1
            }
        })
    }

    const onApply = async (event) => {
        if(isQuoteStartDateBeforeToday()) {
            reOpenQuote()
            return
        }

        // perhaps the email was wrong? Did they edit it?
        applyButtonRef.current = event.target
        const firstLife = toClass(quote.value, Quote).request.getPrimaryLife()
        if (firstLife.email !== draftFirstLife.email.value) {
            const liveLife = quote.request.lives.find(l => l.id.value === firstLife.id)
            liveLife.email.set(draftFirstLife.email.value)
        }

        const phone = libPhoneNumber.parsePhoneNumber(draftFirstLife.phone.value, 'GB').number.toString()

        setIsBuying(true)

        try {
            const draftCardClass = toClass(draftCard.value, CardDetails)

            await iamService.signUp({
                username: draftFirstLife.email.value,
                password: draftFirstLife.password.value,
                phone,
                firstName: draftFirstLife.firstname.value,
                lastName: draftFirstLife.lastname.value
            })
            await policyService.buy(quoteClass, draftCardClass, intercom.getVisitorId())

            setHasBought(true)
            intercom.trackEvent('purchase-complete')
            // on purchase the intercom visitor/lead will be converted to a full user, after which their conversations
            // aren't available without authentication so reboot intercom widget to get a new visitor id
            intercom.reboot()
            setSubmitError('')
        } catch (error) {
            if (error?.toString().startsWith('UsernameExistsException')) {
                setSubmitError('Hmm, that email address appears to be in use. Please check and confirm your email below.')
            } else {
                console.log('Error buying', error)
                setSubmitError(`Houston - we have a problem! We got a code ${error}`)
            }
            intercom.trackEvent('purchase-error')
        } finally {
            setIsBuying(false)
        }
    }

    useAsyncEffect(async () => {
        const firstLife = quoteClass.request.getPrimaryLife()
        draftFirstLife.set(toBlueprint(firstLife))
    }, [])


    useEffect(() => {
        if (hasBought) {
            const button = applyButtonRef.current
            button.innerHTML = ''
            button.blur()
            button.style.backgroundColor = 'var(--peachy-pink)'
            const animation = button.animate(keyframes, options)
            animation.finished.then(() => {
                quoteService.buy()
            })
        }
    }, [hasBought])



    return (<>
        <QuoteHeaderNav onX={NOOP} onBack={onBack}/>
        <main className={styles.QuoteJourney}>
            <PaymentsController>
                <Switcher on={currentPageStage.value}
                          onStage={{position: 'absolute', opacity: 1, transform: 'translateX(0em)'}}
                          offStageLeft={{position: 'absolute', opacity: 0, transform: 'translateX(-10em)'}}
                          offStageRight={{position: 'absolute', opacity: 0, transform: 'translateX(10em)'}}
                >
                    <CaptureCard onSubmit={submitStage} draftCard={draftCard}/>
                    <CapturePhone onSubmit={submitStage} draftLife={draftFirstLife} />
                    <CapturePassword onSubmit={onApply} draftLife={draftFirstLife} submitError={submitError}/>
                </Switcher>
            </PaymentsController>
            {showSpinner && <Spinner />}
        </main>
    </>)
}



function QuoteHeaderNav({onBack, onX}) {

    const quoteNavPortal = useScope<HTMLElement>()

    return quoteNavPortal ? ReactDOM.createPortal(<>
        {onBack &&
        <button className={styles.backButton} onClick={onBack}>
            <i className="fal fa-angle-left"/>
        </button>
        }
        <PeachyLogo/>
        {onX &&
        <button className={styles.exitButton} onClick={onX}>
            <i className="fal fa-times"/>
        </button>
        }
    </>, quoteNavPortal) : null
}
