import React, {useState} from 'react'
import classes from './App.module.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import '../fa-icon-library'
import HomePage from '../pages/HomePage/HomePage'

import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'


import HowItWorks from '../pages/HowItWorks/HowItWorks'
import FaqPage from '../pages/FAQs/FaqPage'
import ContactUs from '../pages/ContactUs/ContactUs'
import TeamHealth from '../pages/TeamHealth/TeamHealth'
import {useLocation} from 'react-router'
import BrowseCare from '../pages/GetCare/BrowseCare/BrowseCare'
import AboutUs from '../pages/AboutUs/AboutUs'
import smoothScroll from 'smoothscroll-polyfill'
import Policies from '../pages/Policies/Policies'
import QuoteJourney from '../Quote/QuoteJourney'
import IamController from '../Quote/IamController'
import PolicyController from '../Quote/PolicyController'
import {configureAws} from '../config/configureAws'
import useAsyncEffect from 'use-async-effect'
import {WebShopServiceController} from '../service/WebShopServiceController'

smoothScroll.polyfill()

export const appRootId = 'app-route'

export let ROOT_DOMAIN = 'peachy.health'

function App() {
    return (
        <BrowserRouter>
            <AppInit/>
        </BrowserRouter>
    )
}

function AppInit() {

    const isLoaded = useGlobalSetup()

    const isQuote = useLocation().pathname === '/quote'
    return isLoaded ? (
        <div id={appRootId} className={classes.App}>
            <WebShopServiceController>
                {/* todo - merge lower controllers with WebShopServiceController */}
                <PolicyController>
                    <IamController>
                        {!isQuote &&
                            <Header/>
                        }
                        <QuoteAndCare/>
                        {!isQuote &&
                            <Footer/>
                        }
                    </IamController>
                </PolicyController>
            </WebShopServiceController>
        </div>
    ) : null
}


function useGlobalSetup() {

    const [done, setDone] = useState(false)

    useAsyncEffect(async (isActive) => {

        await configureAws()

        if (!isActive()) return

        setDone(true)

        const fudgeHeight = () => {
            document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
        }
        fudgeHeight()
        window.addEventListener('resize', fudgeHeight, {passive: true})

        return () => {
            window.removeEventListener('resize', fudgeHeight)
        }


    }, [])

    return done
}

function Shopfront() {
    return (
        <Routes>
            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/how-it-works'} element={<HowItWorks/>}/>
            <Route path={'/for-business'} element={<TeamHealth/>}/>
            <Route path={'/faqs'} element={<FaqPage/>}/>
            <Route path={'/get-care'} element={<BrowseCare/>}/>
            <Route path={'/about-us'} element={<AboutUs/>}/>
            <Route path={'/contact-us'} element={<ContactUs/>}/>
            <Route path={'/policies/*'} element={<Policies/>}/>
            <Route path={'/quote'} element={<QuoteJourney/>}/>
        </Routes>
    )
}

function QuoteAndCare() {
    return (
        <Routes>
            <Route path={'/'} element={<Redirect to={'https://peachy.health'} />}/>
            <Route path={'/how-it-works'} element={<Redirect to={'https://peachy.health/how-it-works'}/>}/>
            <Route path={'/for-business'} element={<Redirect to={'https://peachy.health/for-business'}/>}/>
            <Route path={'/faqs'} element={<Redirect to={'https://intercom-help.eu/peachy/en'}/>}/>
            <Route path={'/get-care'} element={<BrowseCare/>}/>
            <Route path={'/about-us'} element={<Redirect to={'https://peachy.health/about'}/>}/>
            <Route path={'/about'} element={<Redirect to={'https://peachy.health/about'}/>}/>
            <Route path={'/contact-us'} element={<Redirect to={'https://peachy.health/contact'}/>}/>
            <Route path={'/contact'} element={<Redirect to={'https://peachy.health/contact'}/>}/>
            <Route path={'/quote'} element={<QuoteJourney/>}/>

            <Route path={'/*'} element={<Redirect to={'https://peachy.health'}/>}/>
        </Routes>
    )
}


export default App



function Redirect({to}: {to:string}) {
    window.location.replace(to)
    return <></>
}