import {GeoLocation, LatLng, metersPerLongitudinalDegreeAtLatitude, OverlayProps, Vector} from './MapKit'

export interface OverlayAdaptor extends google.maps.OverlayView {
    container: HTMLElement
    setSize(size: number | Vector): void
    setLocation(location: GeoLocation): void
}


export type MapPane = 'mapPane' | 'overlayLayer' | 'markerLayer' | 'overlayMouseTarget' | 'floatPlane'


export type Point = {x:number, y:number}
const origin = {x: 0, y:0}


export type OverlayAdaptorProps = OverlayProps & {
    containerTag?: string
}


export function createOverlayAdaptor (
    {
        location,
        containerTag = 'div',
        anchorPoint = origin,
        targetPane = 'overlayLayer',
        size

    }: OverlayAdaptorProps): OverlayAdaptor {

    class OverlayAdaptor extends google.maps.OverlayView {

        container: HTMLElement
        location: GeoLocation
        size: number | Point

        constructor() {
            super();
            this.container = document.createElement(containerTag)
            this.container.style.position = 'absolute'
            this.location = location
            this.size = size
        }

        onAdd() {
            this.getPanes()[targetPane].appendChild(this.container)
        }

        onRemove() {
            this.container.remove()
        }

        setSize(size: number | Point) {
            this.size = size
        }

        setLocation(location: GeoLocation) {
            this.location = location
        }

        draw() {

            if (!this.getProjection()) {
                return
            }


            const locationPx = this.getProjection().fromLatLngToDivPixel(LatLng(this.location));

            if (this.size) {

                let widthMeters
                let aspectRatio
                if (typeof this.size === 'number') {
                    aspectRatio = 1
                    widthMeters = this.size

                } else {
                    aspectRatio = this.size.x / this.size.y
                    widthMeters = this.size.x
                }


                const widthDegrees = widthMeters / metersPerLongitudinalDegreeAtLatitude(this.location.lat)

                const widthOffset = LatLng({
                    lat: this.location.lat,
                    lng: this.location.lng + widthDegrees
                })

                const widthOffsetPx = this.getProjection().fromLatLngToDivPixel(widthOffset)


                const widthPx = widthOffsetPx.x - locationPx.x
                const heightPx = aspectRatio * widthPx

                this.container.style.left = (locationPx.x - (anchorPoint.x * widthPx)) + "px";
                this.container.style.top = (locationPx.y - (anchorPoint.y * heightPx)) + "px";
                this.container.style.width = widthPx + "px";
                this.container.style.height = heightPx + "px";

            } else {
                this.container.style.left = locationPx.x  + "px";
                this.container.style.top = locationPx.y + "px";
                this.container.style.transform = `translate(-${anchorPoint.x * 100}%,-${anchorPoint.y * 100}%)`
            }
        }
    }

    return new OverlayAdaptor()
}

