import React from 'react'
import library from '../../../global-style/library.module.css'
import {NOOP, classList} from '@peachy/utility-kit-pure'

export type ButtonProps = {
    text: string
    id?: string
    onClick?(e): void
    enabled?: boolean
    className?: string
    buttonRef?: React.Ref<HTMLButtonElement>
}

export default function Button({id, text, onClick = NOOP, enabled = true, className, buttonRef}: ButtonProps) {

    const buttonClasses = classList(library.button, !enabled && library.disabledButton, className)

    const clickHandler = enabled ? onClick : NOOP

    const ref = {ref:buttonRef}

    return (
        <button id={id} {...ref} disabled={!enabled} className={buttonClasses} onClick={(e) => {
            e.preventDefault()
            clickHandler(e)
        }}>{text}</button>
    )
}
