import {Point} from './OverlayAdaptor'

export type Vector = {
    x: number,
    y: number
}


export type GeoLocation = {
    lat: number,
    lng: number
}

export type MapPane = 'mapPane' | 'overlayLayer' | 'markerLayer' | 'overlayMouseTarget' | 'floatPlane'



export const TOP_LEFT = {x: 0, y: 0}
export const TOP_CENTER = {x: 0.5, y: 0}
export const TOP_RIGHT = {x: 1, y: 0}

export const LEFT = {x: 0, y: 0.5}
export const CENTER = {x: 0.5, y: 0.5}
export const RIGHT = {x: 1, y: 0.5}

export const BOTTOM_LEFT = {x: 0, y: 1}
export const BOTTOM_CENTER = {x: 0.5, y: 1}
export const BOTTOM_RIGHT = {x: 1, y: 1}


export type OverlayProps = {
    location?: GeoLocation
    anchorPoint?: Point
    targetPane?: MapPane
    size?: number | Point
}





export function LatLng(l: GeoLocation) {
    return new google.maps.LatLng(l)
}


export function getOffsetCenter(map: google.maps.Map, offset: Vector): GeoLocation {

    const northEastCorner = map.getBounds().getNorthEast()
    const southWestCorner = map.getBounds().getSouthWest()

    const center = map.getBounds().getCenter()

    const width = northEastCorner.lng() - southWestCorner.lng()
    const height = southWestCorner.lat() - northEastCorner.lat()

    return {
        lat: center.lat() + (offset.y * height),
        lng: center.lng() - (offset.x * width),
    }
}

export function getZoomForRange(map: google.maps.Map, rangeInMeters: number) {
    const viewport = getViewport(map)

    const targetViewport = rangeInMeters*4

    let viewportLngMeters = metersPerLongitudinalDegreeAtLatitude(map.getCenter().lat()) * viewport.lng

    let zoom = map.getZoom()

    if (viewportLngMeters < targetViewport) {

        while (viewportLngMeters < (targetViewport)) {
            zoom--
            viewportLngMeters *=2
        }

    } else {

        while (viewportLngMeters > targetViewport*2) {
            zoom++
            viewportLngMeters /=2
        }
    }
    console.log(zoom)
    return zoom
}



export function getViewport(map: google.maps.Map) {
    const north = map.getBounds().getNorthEast().lat()
    const south = map.getBounds().getSouthWest().lat()
    const east = map.getBounds().getNorthEast().lng()
    const west = map.getBounds().getSouthWest().lng()
    return {
        lat: Math.abs(north-south),
        lng: Math.abs(east-west)
    }
}



export function metersPerLongitudinalDegreeAtLatitude(latitude: number) {
    return circumferenceInMetersAtLatitude(latitude) / 360
}

export function longitudinalDegreesPerMeterAtLatitude(latitude: number) {
    return 360 / circumferenceInMetersAtLatitude(latitude)
}

export function circumferenceInMetersAtLatitude(lat: number) {
    return Math.cos(lat * Math.PI / 180) * equatorialCircumferenceMeters
}




export function distanceInMeters(a: GeoLocation, b: GeoLocation) {

    const R = 6371000; // Radius of the earth in m
    const dLat = deg2rad(b.lat-a.lat);  // deg2rad below
    const dLon = deg2rad(b.lng-a.lng);

    const x =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(a.lat)) * Math.cos(deg2rad(b.lat)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1-x));

    console.log({a, b, d: R * c})

    // Distance in m
    return R * c;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}


export function formatDistance(meters: number) {
    if (meters < 1000) {
        return `${meters.toFixed(0)} m`
    } else if (meters < 10000) {
        return `${(meters/1000).toFixed(1)} km`
    } else {
        return `${(meters/1000).toFixed(0)} km`
    }
}


export const equatorialCircumferenceMeters = 40075016
export const meridionalCircumferenceMeters = 40007860

export const metersPerLatitudinalDegree = meridionalCircumferenceMeters / 360
export const latitudinalDegreesPerMeter = 360 / meridionalCircumferenceMeters
