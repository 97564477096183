import React, {useEffect, useState} from 'react'
import {useCurrentQuote, useQuoteStage} from '../../QuoteController'
import styles from './FormattedPrice.module.css'
import useDataThrottle from '../../../hooks/useDataThrottle'
import {QuoteStageNames} from '@peachy/quote-client'
import useIntercom from '../../../hooks/useIntercom'
import {Promo, Quote} from '@peachy/legacy-domain'
import {State as HookState} from '@hookstate/core'
import {asPoundsAndPence, Draft, mapGroupDistinctBy, classList} from '@peachy/utility-kit-pure'
import {isEmpty} from 'lodash-es'
import {useGetPromoIfValidForPrimaryLife} from '../../../hooks/usePromos'

export default function FormattedPrice({notifyCrm}: {notifyCrm?: boolean}) {
    const quote = useCurrentQuote()
    const quoteStage = useQuoteStage()
    const intercom = useIntercom()

    const price = quote.response?.totalPolicyPrice?.value
    const [displayPrice, setDisplayPrice] = useState(price)
    const [pounds, pence] = asPoundsAndPence(displayPrice)

    const promo = useGetPromoIfValidForPrimaryLife()
    const discountedPrice = getDiscountedPrice(displayPrice, promo)
    const [discountedPounds, discountedPence] = asPoundsAndPence(discountedPrice)

    const throttledStage = useDataThrottle(quoteStage.stage.value, 300, 3)

    useEffect(() => {
        setDisplayPrice(price)
    }, [throttledStage])

    useEffect(() => {
        if (notifyCrm) {
            const quoteCrmData = crmQuoteDataFrom(quote)
            !isEmpty(quoteCrmData) && intercom.trackEvent('quote', crmQuoteDataFrom(quote))
        }
    }, [displayPrice])

    const mainPriceClasses = classList(styles.FormattedPrice, throttledStage === QuoteStageNames.PENDING && styles.pending, discountedPrice && styles.beforeDiscount)
    const discountPriceClasses = classList(styles.FormattedPrice, throttledStage === QuoteStageNames.PENDING && styles.pending)

    return (
        <span>
            {displayPrice && <span className={mainPriceClasses}>
                <span>£{pounds}</span>
                <span>.{pence.toString().padStart(2, '0')}</span>
                <span> /month</span>
            </span>}
            {discountedPrice && <span className={discountPriceClasses}>
                <span>£{discountedPounds}</span>
                <span>.{discountedPence.toString().padStart(2, '0')}</span>
                <span> /month</span>
            </span>}
        </span>
    )
}

function getDiscountedPrice(priceInPence: number, promo: Promo | undefined) {
    if (promo?.reflectOnQuotedPrice) {
        let discountInPence = promo.fixedDiscountInPence ?? ((promo.percentDiscount ?? 0) / 100 * priceInPence)
        discountInPence = Math.floor(discountInPence)
        const discountedPrice = discountInPence && discountInPence > 0 ? priceInPence - discountInPence : undefined
        return discountedPrice
    }
}



function crmQuoteDataFrom(quote?: HookState<Draft<Quote>>) {
    return quote && {
        price: quote?.response?.totalPolicyPrice?.value,
        lives: quote?.response?.lives?.value?.length,
        ...mapGroupDistinctBy(quote?.response?.lives?.value?.[0]?.benefitPrices ?? [], it => [it.benefitName, it.benefitPrice])
    }
}