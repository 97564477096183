import React, {useEffect} from 'react'
import {Draft, dateFromIso, Fields} from '@peachy/utility-kit-pure'
import useToggles from '../../../hooks/useToggles'
import CaptureForm, {CaptureFormProps} from '../../DraftLife/CaptureForm'
import TextBox from '../../Elements/TextBox/TextBox'
import {State as HookState} from '@hookstate/core'
import {useCurrentQuote} from '../../QuoteController'
import {Life, toClass, validate} from '@peachy/legacy-domain'
import styles from '../../DraftLife/CaptureAddress/CaptureAddress.module.css'
import Toggle from '../../../elements/Toggle/Toggle'
import useIntercom from '../../../hooks/useIntercom'

const captureFields: Fields<Life>[] = ['phone','smsMarketingAccepted']

export type CapturePhoneProps = {
    onSubmit(): void
    draftLife: HookState<Draft<Life>>
}

export default function CapturePhone({onSubmit, draftLife}: CapturePhoneProps) {

    const quote = useCurrentQuote()
    const intercom = useIntercom()

    const startDate = dateFromIso(quote.request.startDate.value)

    const visitedFields = useToggles<Fields<Life>>(['phone', 'smsMarketingAccepted'])
    const validationErrors = validate(toClass(draftLife.value, Life), startDate, Life, 'BUY')

    const onUpdatePhone = () => {
        const liveLife = quote.request.lives.find(l => l.id.value === draftLife.id.value)
        const phone = draftLife.phone.value
        const email = draftLife.email.value
        const name = draftLife.firstname.value
        liveLife.phone.set(phone)
        if (liveLife.type.value === 'PRIMARY') {
            intercom.convertVisitorToLead({email, name, phone}).then((response) => {
                console.log('CapturePhone convertVisitorToLead', response )
            })
        }
        onSubmit()
    }


    const captureProps: CaptureFormProps<Life> = {
        buttonId: 'capture-phone',
        onSubmit: onUpdatePhone,
        instance: draftLife,
        visitedFields,
        validationErrors,
        headers: <>
            <h4>Almost done!</h4>
            <h4>Please let us know your mobile number</h4>
        </>,
        captureFields,
    }

    useEffect(() => intercom.trackEvent('capture-phone'), [])

    return (
        <CaptureForm   {...captureProps}>
            <TextBox phone autoFocus placeholder={'Number'} value={draftLife.phone.value} setValue={draftLife.phone.set} onBlur={() => visitedFields.toggleOn('phone')}/>
            <fieldset className={styles.optIns}>
                <label>
                    <span>Include me in updates & offers by SMS</span>
                    <Toggle className={styles.toggle} state={draftLife.smsMarketingAccepted.value} setState={draftLife.smsMarketingAccepted.set}/>
                </label>
            </fieldset>
        </CaptureForm>
    )
}
