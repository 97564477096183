import 'reflect-metadata'
import {dateFromIso, enumerate, yearsBetween, yearsSince} from '@peachy/utility-kit-pure'
import {Type} from 'class-transformer'
import IsUUID from './validate/validators/IsUUID'
import MinLength from './validate/validators/MinLength'
import Optional from './validate/validators/Optional'
import IsIn from './validate/validators/IsIn'
import IsEmail from './validate/validators/IsEmail'
import MinAge from './validate/validators/MinAge'
import MaxAge from './validate/validators/MaxAge'
import IsPastIsoDateString from './validate/validators/IsPastIsoDateString'
import Required from './validate/validators/Required'
import IsPhoneNumber from './validate/validators/IsPhoneNumber'
import IsTrue from './validate/validators/IsTrue'
import IsValidPassword, {PasswordRequirements,} from './validate/validators/IsValidPassword'
import {Address} from './location/Address'

export const LifeTypeArray = ['PRIMARY', 'SECONDARY', 'DEPENDANT'] as const
export const GenderTypeArray = ['MALE', 'FEMALE', 'BLENDED'] as const

export const LifeTypes = enumerate(LifeTypeArray)
export type LifeType = keyof typeof LifeTypes;

export const GenderTypes = enumerate(GenderTypeArray)
export type GenderType = keyof typeof GenderTypes;

export class Human {
    @IsUUID()
    id?: string = undefined

    @MinLength(1, 'Please enter your first name')
    firstname: string = undefined

    @MinLength(1, 'Please enter your last name')
    lastname: string = undefined

    @IsPastIsoDateString('Please enter a valid date')
    birthDate: string = undefined

    @IsIn(GenderTypeArray)
    @Optional()
    gender: GenderType = undefined

    @Type(() => Address)
    @Required('Please enter a valid UK address')
    address: Address = undefined

    @IsEmail('Please enter a valid email address')
    email: string = undefined

    @IsPhoneNumber('GB', 'Please enter a valid UK mobile number')
    @Optional()
    phone?: string = undefined

    fullName() {
        return [this.firstname, this.lastname].join(' ')
    }

}

export const LIFE_MAX_AGE = 55
export const CHILD_MAX_AGE = 16
export const DEPENDENT_MAX_AGE = 25

export class Life extends Human {
    @IsIn(LifeTypeArray)
    type: LifeType = undefined

    @Optional()
    cognitoId?: string = undefined

    @IsUUID()
    @Optional()
    planId?: string = undefined

    @MinAge<Life>(
        CHILD_MAX_AGE,
        `We can only offer plans to people over the age of ${CHILD_MAX_AGE}`,
        ({instance}) => instance.is(LifeTypes.PRIMARY, LifeTypes.SECONDARY)
    )
    @MaxAge<Life>(
        LIFE_MAX_AGE,
        `We can only offer plans to people under the age of ${LIFE_MAX_AGE + 1}`,
        ({instance}) => instance.is(LifeTypes.PRIMARY, LifeTypes.SECONDARY)
    )
    @MaxAge<Life>(
        DEPENDENT_MAX_AGE,
        `Dependents must be under the age of ${DEPENDENT_MAX_AGE + 1}`,
        ({instance}) => instance.is(LifeTypes.DEPENDANT)
    )
    @IsPastIsoDateString('Please enter a valid date')
    birthDate: string = undefined

    @Optional()
    @Required<Life>(
        ({instance}) =>
            `Please enter your ${
                instance.is(LifeTypes.SECONDARY) ? 'partner\'s' : 'child\'s'
            } gender`,
        ({instance}) => !instance.is(LifeTypes.PRIMARY)
    )
    gender: GenderType = undefined

    @Optional<Life>(
        true,
        'Please enter a valid email address',
        ({instance, context}) => {
            return !instance.is(LifeTypes.PRIMARY) && instance.age() < CHILD_MAX_AGE
        }
    )
    email: string = undefined

    @Required()
    marketingAccepted: boolean = false

    @Required()
    smsMarketingAccepted: boolean = false

    @IsTrue<Life>(
        'You must agree to our Privacy Policy and Term of Service to proceed...',
        ({instance}) => instance.is(LifeTypes.PRIMARY)
    )
    policiesAccepted: boolean = false

    @IsTrue<Life>('You must confirm UK residency to proceed...')
    ukResidentConfirmed: boolean = false

    @Required<Life>(
        'Please enter a valid UK mobile number',
        ({instance, context}) =>
            instance.is(LifeTypes.PRIMARY) && context === 'BUY'
    )
    phone?: string = undefined

    @IsValidPassword<Life>(
        8,
        [
            PasswordRequirements.NUMBER,
            PasswordRequirements.UPPERCASE,
            PasswordRequirements.LOWERCASE,
        ],
        null,
        ({instance, context}) =>
            instance.is(LifeTypes.PRIMARY) && context === 'BUY'
    )
    password: string

    is(...types: LifeType[]) {
        return types.includes(this.type)
    }

    age() {
        let age = undefined
        if (this.birthDate) {
            const date = dateFromIso(this.birthDate)
            if (date) {
                age = yearsSince(date)
            }
        }
        return age
    }

    ageAt(aDate: Date) {
        let ageAt = undefined
        if (this.birthDate) {
            const bDate = dateFromIso(this.birthDate)
            if (bDate) {
                ageAt = yearsBetween(bDate, aDate)
            }
        }
        return ageAt
    }
}
