import React from 'react'
import TextBox from '../Elements/TextBox/TextBox'
import CaptureForm, {CaptureFieldsProps, CaptureFormProps} from './CaptureForm'
import {Life} from '@peachy/legacy-domain'
import {last} from '@peachy/utility-kit-pure'

export default function CaptureName(props: CaptureFieldsProps<Life>) {
    const {instance, visitedFields} = props

    const captureNameProps: CaptureFormProps<Life> = {
        ...props,
        buttonId: 'capture-name',
        headers: <>
            <h4>We'll get you an awesome price in seconds. Let's go!</h4>
        </>,
        captureFields:['firstname', 'lastname']
    }

    return (
        <CaptureForm  {...captureNameProps}>
            <fieldset>
                <TextBox autoCapitalize={true} autoFocus placeholder={'First name'} value={instance.firstname.value} setValue={instance.firstname.set} onBlur={() => visitedFields.toggleOn('firstname')}/>
                <TextBox placeholder={'Last name'} value={instance.lastname.value} setValue={instance.lastname.set} onBlur={() => visitedFields.toggleOn('lastname')}/>
            </fieldset>
        </CaptureForm>
    )
}


function autoCapitalise(name: string, left: boolean) {
    if (left) {
        return name.split(/\s/).map(n => initCap(n)).join(' ')

    } else {
        const lastPart = last(name.split(/\W/))
        if (!lastPart) return name
        const lastIndex = name.lastIndexOf(lastPart)
        return name.substring(0, lastIndex) + initCap(lastPart)
    }
}


function initCap(s: string):string {
    const [init, ...rest] = s
    if (!init) return s
    return [init.toUpperCase(), ...rest].join('')
}
