import React, {ReactNode} from 'react'
import styles from './CaptureForm.module.css'
import Expander from '../../elements/Expander/Expander'
import Button from '../Elements/Button/Button'
import {any, Draft, Fields, isNullish, classList} from '@peachy/utility-kit-pure'
import {Toggles} from '../../hooks/useToggles'
import {State as HookState} from '@hookstate/core'
import _ from 'lodash-es'
import {ValidationErrorMap} from '@peachy/legacy-domain'


export type CaptureFieldsProps<T extends object> = {
    instance: HookState<Draft<T>>
    visitedFields: Toggles<Fields<T>>
    validationErrors: ValidationErrorMap<T>
    submitError?: string
    captureStage?: number
    vetoSubmit?: boolean
    onSubmit(skip?: boolean): void
}


export type CaptureFormProps<T extends object> = CaptureFieldsProps<T> & {
    captureFields?: Fields<T>[]
    headers: ReactNode
    className?: string
    buttonText?: string
    buttonId?: string,
    children?: ReactNode
}

export default function CaptureForm<T extends object>(
    {
        captureFields,
        visitedFields,
        validationErrors,
        headers,
        submitError,
        className,
        children,
        buttonText = 'Next',
        buttonId,
        onSubmit,
        vetoSubmit = false,
        captureStage

    }: CaptureFormProps<T>) {

    // errors for all captured fields
    const fieldErrors = _.pick(validationErrors, captureFields) as ValidationErrorMap<T>

    const enableSubmit = !any(fieldErrors) && !vetoSubmit


    // filter errors for visited fields only
    const errorsToDisplay = captureFields.map((field) => visitedFields.on.includes(field) ? fieldErrors?.[field as string] ?? [] : []).flat()

    // if any errors just show the first
    const errorIfAny = errorsToDisplay[0]?.message ?? submitError

    const formClasses = classList(styles.CaptureForm, className)

    return (
        <form className={formClasses}>
            <legend>
                {!isNullish(captureStage) &&
                    <span>{captureStage+1 } of 4</span>
                }
                <hgroup>{headers}</hgroup>
            </legend>
            <Expander expanded={!!errorIfAny} tag={'mark'}>{errorIfAny}</Expander>
            {children}
            <Button id={buttonId} text={buttonText} onClick={onSubmit} enabled={enableSubmit}/>
            {/*<Button text={'Next →'} onClick={onSubmit} enabled={enableSubmit}/>*/}
        </form>
    )
}
